<template>
    <div class="text-overflow">{{ item.fullName }}</div>
    <div class="text-overflow">{{ item.email }}</div>
    <div class="text-overflow">{{ item.percent }}%</div>
    <div class="text-overflow">${{ CONVERT_PRICE(Number(item.payments.total).toFixed(2)) }}</div>
    <div class="text-overflow"><a href="" @click.prevent="createPayment">${{ CONVERT_PRICE(Number(item.outstandingPayments.total).toFixed(2)) }}</a></div>
    <div class="text-overflow user-status-holder"><span class="user-status" :class="[status.class]">{{ status.value }}</span></div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['item'],
        emits: ['createPayment'],
        computed: {
            status() {
                return this.item.removed ? { value: 'Removed', class: 'removed' } : { value: 'Active', class: 'active' };
            }
        },
        methods: {
            createPayment() {
                this.$emit('createPayment', this.item._id);
            }
        },
    }
</script>

<style lang="scss" scoped>
.user-status-holder {
    display: flex;
}

.user-status {
    padding: 0 10px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 11px;
    font-size: 10px;
    &.active {
        color: #fff;
        background: $success;
    }
    &.removed {
        background: $error;
        color: #fff;
    }
    &.pending {
        background: $themeColor2;
        color: #fff;
    }
}
</style>