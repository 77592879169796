<template>
    <div class="loads-picker-wrapper">

        <TableView :head="table.head" :size="table.sizes" :loading="isLoading(['getListOfUnpaidLoads'])">
            <TableRow :size="table.sizes" :key="load._id" v-for="load of loads">
                <div>
                    <Checkbox v-model="selected" :val="load.loadId" />
                </div>
                <div>{{ getLoadDate(load.loads.date) }}</div>
                <div class="text-overflow">{{ load.companyName }}</div>
                <div>{{ load.loads.origin }}</div>
                <div>{{ load.loads.destination }}</div>
                <div>${{ CONVERT_PRICE(load.loads.rate.toFixed(2)) }}</div>
                <div><strong>${{ CONVERT_PRICE(load.loads.referralPay.amount.toFixed(2)) }}</strong></div>
                <div>{{ CONVERT_PRICE(load.loads.referralPay.percent.toFixed(2)) }}%</div>
                <div>{{ load.loads.invoices ? load.loads.invoices.status : 'Not invoiced' }}</div>
            </TableRow>
        </TableView>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['referralId', 'list', 'modal'],
    data() {
        return {
            table: {
                head: [
                    { name: '' },
                    { name: 'Date' },
                    { name: 'Carrier' },
                    { name: 'Origin' },
                    { name: 'Destination' },
                    { name: 'Rate' },
                    { name: 'To Pay' },
                    { name: 'Percent' },
                    { name: 'Invoice' },
                ],
                sizes: ['34px', 'minmax(80px, 100px)', 'minmax(120px, 1fr)', 'minmax(120px, 1fr)', 'minmax(120px, 1fr)', 'minmax(80px, 100px)', '60px', '50px', '100px']
            },
            loads: [],
            selected: this.list || []
        }
    },
    methods: {
        getLoadDate(value) {
            return moment.utc(value).format("ddd D MMM")
        },
        getListOfUnpaidLoads() {
            this.ajax('getListOfUnpaidLoads', {
                url: `/referrals/payments/${this.referralId}/available-loads`,
                method: 'get'
            }, (err, body) => {
                if (err) {
                    this.modal.close();
                    return;
                }
                this.loads = body.loads || [];
            })
        }
    },
    mounted() {
        this.getListOfUnpaidLoads();
    },
    watch: {
        selected() {
            this.modal.setProp('list', this.selected);
        }
    }
}
</script>

<style lang="scss" scoped>
.loads-picker-wrapper {
    padding: 10px;
}
</style>