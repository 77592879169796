<template>
    <div class="modalWrapper">
        <InputDropDownList placeholder="Select referral" name="Referral user" v-model="payment.userId"
            :error="errors.userId" :showAttribute="(user) => { return `${user.fullName} (${user.email})` }"
            selectAttribute="_id" :options="referrals" />


        <div class="input" v-if="payment.userId">
            <div class="_header">
                <label>Loads</label>
                <span class="_error">{{ errors.loads }}</span>
            </div>

            <div class="loads-picker">
                <div class="loads-list" v-if="payment.loadIds && payment.loadIds.length > 0">{{ payment.loadIds.length
                    }} {{
                        payment.loadIds.length === 1 ? 'load' : 'loads' }} selected</div>
                <div class="loads-list" v-else>No loads selected</div>
                <a href="" @click.prevent="selectLoads">Select loads</a>
            </div>
        </div>

        <div class="payment-total" >Total: <strong>${{ CONVERT_PRICE(payment.total.toFixed(2)) }}</strong></div>

    </div>
</template>

<script>
import LoadsPicker from './LoadsPicker.vue'

export default {
    props: ['modal', 'referral'],
    data() {
        return {
            referrals: [],
            payment: {
                userId: this.referral || null,
                loadIds: [],
                total: 0
            },
            errors: {}
        }
    },
    watch: {
        "payment.userId": {
            deep: true,
            handler() {
                this.payment.loadIds = [];
                this.payment.total = 0;
            }
        },
        "payment.loadIds": {
            deep: true,
            handler() {
                this.validatePayment();
            }
        }
    },
    methods: {
        validatePayment() {
            this.ajax('ValidatePayment', {
                url: `/referrals/payments/${this.payment.userId}/total`,
                method: 'post',
                data: {
                    loads: this.payment.loadIds
                }
            }, (err, body) => {
                if (err) {
                    if (body.errors) this.errors = body.errors;
                    else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                    return;
                }
                
                this.payment.total = body.total;
            });

        },
        getListOfReferrals() {
            this.ajax('GetListOfReferrals', {
                url: `/referrals/all`,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this.referrals = body;
                }
            });
        },
        selectLoads() {
            let list = this.payment.loadIds ? JSON.parse(JSON.stringify(this.payment.loadIds)) : [];

            let modal = this.$ShowModal({
                title: 'Loads',
                description: 'Select loads',
                component: LoadsPicker,
                confirm: 'Select',
                width: '1260px',
                props: {
                    referralId: this.payment.userId,
                    list: list
                }
            });

            modal.onConfirm((props) => {
                if (props && props.list) {
                    this.payment.loadIds = props.list;
                }
                modal.close();
            });

        },
        createPayment() {

            this.ajax('CreatePayment', {
                url: `/referrals/payments/${this.payment.userId}`,
                method: 'post',
                data: {
                    loads: this.payment.loadIds
                }
            }, (err, body) => {
                if (err) {
                    if (body.errors) this.errors = body.errors;
                    else this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                    return;
                }
                this.modal.close();
            });
        }
    },
    mounted() {
        this.getListOfReferrals();
        this.modal.onConfirm(this.createPayment);
    }
}
</script>

<style lang="scss" scoped>
.modalWrapper {
    padding: 20px;
    display: grid;
    gap: 10px;
}

.payment-total {
    font-size: 20px;
}
</style>