<template>
    <div class="grid gap-50">

        <ActionBar title="Users">
            <Button text="Add User" size="small" @click="addUser" />
        </ActionBar>


        <div class="roles-content grid row-20">


            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search users..." />

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value"
                        showAttribute="name"
                        :options="[{ value: null, name: 'All' }, { value: 'active', name: 'Active' }, { value: 'removed', name: 'Removed' }]" />
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)"
                    :loading="isLoading(['GetUsers'])">
                    <TableRow :size="columnsSizes" :key="item._id" v-for="(item, key) of _pageManager.list"
                        @dblclick="saveUser(item, key)" :menu="getUserMenu(item)" @edit="saveUser(item, key)"
                        @remove="removeUser(item)" @restore="restoreUser(item)" @login="loginAsReferral(item._id)" @newPassword="generateNewPassword(item._id)">
                        <UserItem :item="item" @createPayment="createPayment" />
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetUsers'])" class="spinner-flex">
                        <Spinner />
                    </div>
                    <div v-else>
                        <NoDataMessage text="Nothing found" />
                    </div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }" />
            </div>

        </div>

    </div>
</template>

<script>
import PaginationJS from '../../../mixins/Pagination'
import Pagination from '../../../mixins/Pagination/components/Pagination.vue'

import UserItem from './components/UserItem.vue'
import AddUserModal from './components/AddUser.vue'
import UpdateUserModal from './components/UpdateUser.vue'

import CreatePaymentModal from './components/CreatePayment'

export default {
    mixins: [PaginationJS],
    components: {
        Pagination,
        UserItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Full Name' },
                { name: 'Email address' },
                { name: 'Charge' },
                { name: 'Total Paid' },
                { name: 'Balance' },
                { name: 'Status' },
                { name: '' },
            ],
            columnsSizes: [
                'minmax(50px, 1fr)', // full name
                'minmax(50px, 1fr)', // email
                '50px', // charge percent
                'minmax(50px, 80px)', // total paid
                'minmax(50px, 80px)', // outstanding balance
                'minmax(50px, 120px)', // status
                '34px' // button
            ],
        }
    },
    watch: {
        "_pageManager.query.search": {
            deep: true,
            handler() {
                this._pageManager.query.page = 1;
            }
        },
        "_pageManager.query": {
            deep: true,
            handler() {
                this.getUsers();
            }
        }
    },
    methods: {
        async generateNewPassword(userId) {
            let confirmNewPass = await this.$ShowConfirm('Do you really wish to generate new password for this user?');
            if (!confirmNewPass) return;

            await this.ajax('GenerateNewPassword', {
                url: `/referrals/${userId}/password`,
                method: 'POST',
            }, (err, body) => {
                if (err) {
                    this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                } else {
                    this.$ShowAlert(`New Password: ${body.password}`);
                }
            });

        },
        async loginAsReferral(userId) {
            await this.ajax('LoginAsReferral', {
                url: `/referrals/${userId}/login`,
                method: 'GET',
            }, (err, body) => {
                if (err) {
                    this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                } else {
                    if (body.token) {
                        localStorage.setItem('referral-token', body.token);
                        const routeData = this.$router.resolve({ name: 'ReferralAppMain' });
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                    }
                }
            });
        },
        createPayment(userId) {
            const modal = this.$ShowModal({
                title: 'Create New Payment',
                component: CreatePaymentModal,
                props: {
                    referral: userId
                }
            });

            modal.onClose(() => {
                this.getUsers();
            });
        },
        getUserMenu(item) {
            let array = [{ name: 'Edit user', emit: 'edit' }, { name: 'Log in', emit: 'login' }, { name: 'Generate New Password', emit: 'newPassword' }];

            if (item.removed) {
                array.push({ name: 'Restore user', emit: 'restore' })
            } else {
                array.push({ name: 'Remove user', emit: 'remove', type: 'remove' })
            }

            return array;
        },
        getUsers() {
            let query = new URLSearchParams(this._pageManager.query).toString();

            this.ajax('GetUsers', {
                url: '/referrals?' + query,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this._pageManager.pages = body.pages;
                    this._pageManager.count = body.count;
                    this._pageManager.list = body.list;
                    this._pageManager.page = body.page;
                    this._pageManager.hasNextPage = body.hasNextPage;
                    this._pageManager.hasPrevPage = body.hasPrevPage;
                }
            });
        },
        async removeUser(item) {
            let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this user?');
            if (!confirmRemove) return;

            await this.ajax('RemoveUser', {
                url: `/referrals/remove/${item._id}`,
                method: 'PUT',
            }, (err, body) => { });

            item.removed = true;
        },
        async restoreUser(item) {
            let confirmRemove = await this.$ShowConfirm('Do you really wish to restore account for this user?');
            if (!confirmRemove) return;

            await this.ajax('Restore User', {
                url: `/referrals/restore/${item._id}`,
                method: 'PUT',
            }, (err, body) => { });

            item.removed = false;
        },
        addUser() {
            let modal = this.$ShowModal({
                title: 'Register User',
                description: 'Enter new user details',
                component: AddUserModal,
            });

            modal.onClose(() => {
                this.getUsers();
            });
        },
        saveUser(user, key) {
            let modal = this.$ShowModal({
                title: 'Update User',
                description: 'Update user details',
                component: UpdateUserModal,
                props: {
                    userDetails: JSON.parse(JSON.stringify(user))
                }
            });

            modal.onClose(() => {
                this.getUsers();
            });
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>