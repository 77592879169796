<template>
    <div class="grid gap-10">
        <Input name="Full Name" v-model="item.fullName" :error="errors.fullName" placeholder="Enter name"/>
        <Input name="Email address" v-model="item.email" :error="errors.email" placeholder="Enter email address" description="Email address is used to log in"/>
        <Input name="Percent" v-model="item.percent" :error="errors.percent" placeholder="Enter percent" description="Percent paid for every load booked"/>
    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'errors'],
        data() {
            return {
                item: this.modelValue || {
                    fullName: '',
                    email: '',
                    percent: '1'
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>