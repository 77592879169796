<template>
    <div style="padding: 20px;">
        <UserInfo v-model="item" :errors="errors" />
    </div>
</template>

<script>
import UserInfo from './UserInfo.vue'

export default {
    props: ['modal'],
    components: {
        UserInfo
    },
    data() {
        return {
            item: {
                fullName: '',
                email: '',
                percent: '1'
            },
            errors: {}
        }
    },
    mounted() {

        this.modal.onConfirm(() => {
            this.modal.processing(true);

            this.ajax('RegisterNewUser', {
                url: '/referrals',
                method: 'POST',
                data: this.item
            }, (err, body) => {
                if (err) {
                    if (body.errors) this.errors = body.errors;
                    else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                    return;
                }
                this.modal.close();
            });

            this.modal.processing(false);
        });
    }
}
</script>

<style lang="scss" scoped></style>